<template>
  <div class="toggle-container">
    <span class="toggle-title">
      <strong>
        Confirme que ha recibido un diagnóstico de ambliopía por parte de un especialista (Oftalmólogo u Optometrista) y ha determinado la conveniencia de realizar terapia visual.
      </strong>
    </span>
    <label class="switch">
      <input type="checkbox" v-model="localDiagnostic">
      <span class="slider round"></span>
    </label>
  </div>
</template>
  
  <script>
  export default {
  name: 'DiagnosticToggle',
  props: ['formData'],
  data() {
    return {
      // Initialize local data property with the value from props
      localDiagnostic: this.formData.diagnostic
    };
  },
  watch: {
    // Watch for changes in the toggle state
    localDiagnostic(newVal) {
      // Emit an event with the updated value
      this.$emit('update-diagnostic', newVal);
    }
  }
};
  
  </script>
  
  <style>
.toggle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f8f8f8; 
  border-radius: 8px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  margin: auto;
  max-width: 600px; 
}

.toggle-title {
  margin-bottom: 10px;
  color:  #0074B9; 
  font-size: 1.1em; 
  text-align: left;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; 
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px; 
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%; 
}

input:checked + .slider {
  background-color: #2196F3; 
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
  </style>
  