<script>
export default {
  name: 'App',
  data() {
    return {
      is_allowed: false
    }
  },
  mounted() {
    
    // Obtener la URL de referencia del documento
    const referer = document.referrer;
    const url_list = ['avantsalud.es', 'opensaludpass.com', 'allianzdecesossalud.es', 'mutuacat.salutessencial.cat', 'scdirecto.com', 'hna.es', 'naradigital.es', 'https://dicopt-test.web.app/']

    // Verificar si la URL de referencia está en la lista de permitidos
    if (url_list.some(url => referer.includes(url))) {
      // Permitir el acceso a la segunda página web
      console.log('Acceso permitido.');
      this.is_allowed = true;
    } else {
      // Redirigir o denegar el acceso
      console.log('Acceso denegado.');
      // redirigir a https://dicopt.com/
      window.location.href = 'https://dicopt.com/';
    }
  }
}
</script>

<template>
  <div v-if="is_allowed">
    <router-view></router-view> <!-- This replaces <MainComponent /> -->
  </div>
</template>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
