<template>
    <div class="form-container">
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="centerAndSpecialist">¿En qué centro y qué especialista ha realizado el diagnóstico?</label>
          <input type="text" id="centerAndSpecialist" v-model="localCenterAndSpecialist" required>
        </div>
        
        <div class="form-group">
          <label for="ambliopeAcuity">Valores previos de agudeza visual ojo AMBLIOPE</label>
          <span class="input-example">Ejemplo: 0.5, 1.0, etc.</span>
          <input type="number" id="ambliopeAcuity" v-model="localAmbliopeAcuity" step="0.1" required>
        </div>

        <div class="form-group">
          <label for="visualAcuity">Valores previos de agudeza visual ojo SANO</label>
          <span class="input-example">Ejemplo: 0.5, 1.0, etc.</span>
          <input type="number" id="visualAcuity" v-model="localVisualAcuity" step="0.1" required>
        </div>
  
        <div class="form-group">
          <label>¿Existe supresión en el ojo amblíope?</label>
          <div class="radio-buttons">
            <label for="suppressionYes">Sí
              <input type="radio" id="suppressionYes" value="Sí" v-model="localSuppression" required>
            </label>
            <label for="suppressionNo">No
              <input type="radio" id="suppressionNo" value="No" v-model="localSuppression" required>
            </label>
            <label for="suppressionUnknown">No lo sé
              <input type="radio" id="suppressionUnknown" value="No lo sé" v-model="localSuppression" required>
            </label>
          </div>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SpecialistFormComponent',
  props: ['formData'],
  data() {
  return {
    localCenterAndSpecialist: this.formData.centerAndSpecialist,
    localAmbliopeAcuity: this.formData.ambliopeAcuity,
    localVisualAcuity: this.formData.visualAcuity,
    localSuppression: this.formData.suppression,
    }
  },
  watch: {
    localCenterAndSpecialist(newVal) {
      this.$emit('update-centerandspecialist', newVal);
    },
    localAmbliopeAcuity(newVal) {
      this.$emit('update-ambliopeacuity', newVal);
    }, 
    localVisualAcuity(newVal) {
      this.$emit('update-visualacuity', newVal);
    },
    localSuppression(newVal) {
      this.$emit('update-suppression', newVal);
    }
  }
  };
  </script>
  
  <style>
  .input-example {
    display: block;
    margin-top: 5px;
    font-size: 0.9em;
    color: #666;
  text-align: left;

  }
  .form-container {
    max-width: 500px;
    margin: auto;
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 10px;
  }
  
  .form-group input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    }
  
    .radio-buttons {
  display: flex;
  align-items: center;
  gap: 15px; 
}

.radio-buttons label {
    margin:auto;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.radio-buttons input[type="radio"] {
  margin-right: 5px; 
  align-self: center; 
}
  </style>
  