<template>
  <div class="page-container">
    <!-- HEADER -->
    <div>
      <logo-component />
    </div>
    <div>
      <title-component />
    </div>
    <!-- END HEADER -->
    <!-- BODY -->
    <div class="info-container">
      <info-component />
    </div>
    <div>
      <icon-component />
    </div>
    <div>
      <buttons-component />
    </div>
    <!-- END BODY -->
    <!-- FOOTER -->
    <div>
      <footer-component />
    </div>
    <!-- END FOOTER -->
  </div>
</template>

<script>
import LogoComponent from './Header/LogoComponent.vue';
import TitleComponent from './Header/TitleComponent.vue';
import InformationComponent from './Body/InformationComponent.vue';
import ButtonsComponent from './Body/ButtonsComponent.vue';
import FooterComponent from './Footer/FooterComponent.vue';
import IconRowComponent from './Body/IconRowComponent.vue';

export default {
  name: 'MainComponent',
  components: {
    'logo-component': LogoComponent,
    'title-component': TitleComponent,
    'info-component': InformationComponent,
    'buttons-component': ButtonsComponent,
    'footer-component': FooterComponent,
    'icon-component': IconRowComponent
  }
}
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.page-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.info-container {
  display:flex;
  justify-content: space-around;
  min-height: 100%;
  
}
</style>