<template>
<div>

  <div class="form-container">
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="name">Nombre:</label>
        <input type="text" id="name" v-model="localName" required>
      </div>
      <div class="form-group">
        <label for="surname">Apellido(s):</label>
        <input type="text" id="surname" v-model="localSurname" required>
      </div>
      <div class="form-group">
        <label for="phone">Número de teléfono:</label>
        <input type="number" id="phone" v-model="localPhoneNumber" required>
      </div>
      <div class="form-group">
        <label for="email">Correo electrónico:</label>
        <input type="email" id="email" v-model="localEmail" required>
      </div>
    </form>
  </div>
</div>
</template>

<script>


export default {
  name: 'FormComponent',
  props: ['formData'],
  data() {
  return {
    localName: this.formData.name,
    localSurname: this.formData.surname,
    localPhoneNumber: this.formData.phoneNumber,
    localEmail: this.formData.email,
    };
  },
  watch: {
    localName(newVal) {
      this.$emit('update-name', newVal);
    },
    localSurname(newVal) {
      this.$emit('update-surname', newVal);
    }, 
    localPhoneNumber(newVal) {
      this.$emit('update-phonenumber', newVal);
    },
    localEmail(newVal) {
      this.$emit('update-email', newVal);
    }
  }
};
</script>

<style>
.form-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  background-color: #f8f8f8; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  border-radius: 8px; 
}

.form-group {
  margin-bottom: 20px; 
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-size: 1.1em;
  color:  #0074B9; 
  font-weight: 600; 
  text-align: left;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s; 
}

.form-group input:focus {
  border-color:  #0074B9; 
  outline: none; 
}

</style>
