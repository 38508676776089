// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDGwm4Gi4kMtFBMdk6Tsrpv7Wyyp9xhdzA",
  authDomain: "avantsalud-dicopt.firebaseapp.com",
  projectId: "avantsalud-dicopt",
  storageBucket: "avantsalud-dicopt.appspot.com",
  messagingSenderId: "222514889479",
  appId: "1:222514889479:web:57f5909bd559d908be7ba2"
};

// Initialize Firebase
initializeApp(firebaseConfig);