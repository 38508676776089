<template>
  <div class="flex-container">
    <div class="image-container">
      <br>
      <img alt="Dicopt studio" src="./../../assets/dicopt-studio.png">
    </div>
    <div class="text-container">
      <h3>
        <strong>Dicopt es la terapia visual para ojo vago más divertida y eficaz.</strong>
      </h3>
      <p>
        Terapia visual para ambliopía
        <strong>
          eficaz y divertida
        </strong>. 
        Aumenta la eficacia de la terapia de recuperación para ojo vago mediante 
        <strong>
          juegos en realidad virtual
        </strong>. 
        Dicopt es una app que se instala en tu teléfono móvil y que contiene varios juegos con los que el paciente se divierte mientras realiza la terapia visual. Esto permite realizar la
        terapia en cualquier lugar, adaptándose al estilo de vida de las familias.
        <br><br>
        Dicopt es un Producto Sanitario con marcado CE.
        <br>
        Nº de Licencia de AEMPS: 7994-PS.
      </p>
    </div>
  </div>
</template>
  
<script>
  export default {
    name: 'InformationComponent',
  }
</script>

<style>
.flex-container {
  display: flex;
  justify-content: space-between;
  padding: 50px;
  padding-bottom: 0px;
}

.image-container, .text-container {
  flex: 1;
}

.text-container{
  max-width: 700px;
  display: block;
  margin: 0 auto;
}

.image-container img {
  max-width: 700px;
  max-height: 700px;
  display: block;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .flex-container {
    flex-direction: column;
    padding: 15px;
    padding-bottom: 0px;
    padding-top: 5px;
  }

  .image-container, .text-container {
    flex-basis: auto; 
  }

  .image-container img {
    max-width: 500px;
    max-height: 500px;
    display: block;
  } 

  .text-container {
    padding-top: 50px;
    margin-left: 0px;
  }
}
</style>

  