<template>
  <div class="main-component">
    <LogoComponent></LogoComponent>
  <TitleComponent></TitleComponent>

  <div class="progress-bar-container">
      <div class="progress-bar" :style="{ width: progressBarWidth }"></div>
    </div>

  <div>
    <div class="diagnotstic-container" v-if="currentStep === 1">
      <diagnostic-toggle
      :form-data="formData"
      @update-diagnostic="handleDiagnosticUpdate"
      ></diagnostic-toggle>
      <div class="button-container">
      <button class="next-button" @click="nextStep" :disabled=!this.formData.diagnostic >Siguiente</button>
    </div>
  </div>

    <div class="diagnotstic-container" v-if="currentStep === 2">
      <form-component 
      :form-data="formData"
      @update-name="handleName"
      @update-surname="handleSurname"
      @update-phonenumber="handlePhoneNumber"
      @update-email="handleEmail"
      ></form-component>
    <div class="button-container">
      <button class="next-button" @click="prevStep">Anterior</button>
      <button class="next-button" @click="nextStep" :disabled="isNextButtonDisabled">Siguiente</button>
    </div>
    </div>

    <div class="diagnotstic-container" v-if="currentStep === 3">
      <specialist-form-component 
      :form-data="formData"
      @update-centerandspecialist="handleCenterAndSpecialist"
      @update-ambliopeacuity="handleAmbliopeAcuity"
      @update-visualacuity="handleVisualAcuity"
      @update-suppression="handleSupression" 
      ></specialist-form-component>
      <div class="button-container">
      <button class="next-button" @click="prevStep">Anterior</button>
      <button class="next-button" @click="nextStep" :disabled="isFinalizeButtonDisabled">Finalizar</button>
    </div>
    </div>
  </div>
    <FooterComponent class="footer"></FooterComponent>
  </div>


</template>

<script>
import DiagnosticToggle from './DiagnosticToggle.vue';
import FormComponent from './FormComponent.vue';
import SpecialistFormComponent from './SpecialistFormComponent.vue';
import LogoComponent from '../Header/LogoComponent.vue';
import TitleComponent from '../Header/TitleComponent.vue';
import FooterComponent from '../Footer/FooterComponent.vue';
import { getFirestore, addDoc, collection } from "firebase/firestore";
import app from "../../firebaseConfig";

const db = getFirestore(app);

export default {
  components: {
    DiagnosticToggle,
    FormComponent,
    SpecialistFormComponent,
    LogoComponent,
    TitleComponent,
    FooterComponent
  },
  data() {
    return {
      currentStep: 1,
      totalSteps: 3,
      formData: {
        diagnostic: false,
        name: '',
        surname: '',
        phoneNumber: '',
        email: '',
        centerAndSpecialist: '',
        ambliopeAcuity: 0,
        visualAcuity: 0,
        suppression: ''
      }
    };
  },
  computed: {
    progressBarWidth() {
      return `${(this.currentStep / this.totalSteps) * 100}%`;
    },
    isNextButtonDisabled() {
      return !this.formData.name || !this.formData.surname || !this.formData.email || !this.formData.phoneNumber;
    },
    isFinalizeButtonDisabled() {
      // comprobar si ambliopeAcuity y visualAcuity son números
      let ambliopeAcuityIsNumber = this.formData.ambliopeAcuity || this.formData.ambliopeAcuity === 0;
      let visualAcuityIsNumber = this.formData.visualAcuity || this.formData.visualAcuity === 0;
      return !this.formData.centerAndSpecialist || !this.formData.suppression || !visualAcuityIsNumber || !ambliopeAcuityIsNumber;
    }
  },
  methods: {
    nextStep() {
      if (this.currentStep < 3) {
      this.currentStep++;
    } else {
      this.sendDataToFirebase();
    }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    handleDiagnosticUpdate(newDiagnosticValue) {
      this.formData.diagnostic = newDiagnosticValue;
    },
    handleName(newName) {
      this.formData.name = newName;
    },
    handleSurname(newSurname) {
      this.formData.surname = newSurname;
    },
    handlePhoneNumber(newPhone) {
      this.formData.phoneNumber = newPhone;
    },
    handleEmail(newEmail) {
      this.formData.email = newEmail;
    },
    handleCenterAndSpecialist(newCenterAndSpecialist) {
      this.formData.centerAndSpecialist = newCenterAndSpecialist;
    },
    handleAmbliopeAcuity(newAmbliopeValue) {
      this.formData.ambliopeAcuity = newAmbliopeValue;
    },
    handleVisualAcuity(newVisualAcuity) {
      this.formData.visualAcuity = newVisualAcuity;
    },
    handleSupression(newSupression) {
      this.formData.suppression = newSupression;
    },
    sendDataToFirebase() {
      const dataToAdd = {
        name: this.formData.name,
        surname: this.formData.surname,
        phoneNumber: this.formData.phoneNumber,
        email: this.formData.email,
        diagnostic: this.formData.diagnostic,
        centerAndSpecialist: this.formData.centerAndSpecialist,
        ambliopeAcuity: this.formData.ambliopeAcuity,
        visualAcuity: this.formData.visualAcuity,
        suppression: this.formData.suppression,
        timestamp: new Date().toISOString() 
      };

      const collectionRef = collection(db, "form-data"); // Reference to the 'usuarios' collection

      addDoc(collectionRef, dataToAdd)
        .then((docRef) => {
          console.log('Document written with ID: ', docRef.id);
          window.location.href = "https://buy.stripe.com/7sI9EE7Wk1l13gk005"
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
          this.$router.push('/error');
        });
      }
  }
};
</script>

<style>
/* Footer styles */
.footer {
  bottom: 0;
  min-width: 100%;
}
.diagnotstic-container{
  padding:20px;
}
.main-component {
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  height: 10px;
  border-radius: 5px;
}

.progress-bar {
  height: 100%;
  background-color:  #0074B9;
  width: 0%;
  border-radius: 5px;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 25px; 
    margin-top: 25px; 
  }

.next-button {
  background-color:  #0074B9; 
  color: white;
  padding: 10px 20px; 
  border: none; 
  border-radius: 5px;
  cursor: pointer; 
  font-size: 16px; 
  transition: background-color 0.3s; 
}

.next-button:disabled {
  background-color:  grey; 
  color: white;
  padding: 10px 20px; 
  border: none; 
  border-radius: 5px;
  font-size: 16px; 
  transition: background-color 0.3s; 
  cursor: not-allowed;
}

.next-button:disabled:hover {
  background-color:  grey; 
  color: white;
}

.next-button:hover {
  background-color: darkblue; 
}
</style>
