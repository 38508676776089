<template>
    <div class="icon-row-container">
      <div class="icon-item" v-for="icon in icons" :key="icon.id">
        <div class="icon-circle">
          <img :src="icon.imgSrc" alt="Icon Image">
        </div>
        <div class="icon-text">{{ icon.text }}</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'IconRowComponent',
    data() {
      return {
        icons: [
          { id: 1, imgSrc: require('@/assets/icono1.png'), text: 'Para usarlo, solo necesitarás: un móvil, un visor de VR y un mando de videojuegos que se conecte al dispositivo.' },
          { id: 2, imgSrc: require('@/assets/icono2.png'), text: 'El especialista puede personalizar el seguimiento y atención al paciente, siendo único el tratamiento para cada persona.' },
          { id: 3, imgSrc: require('@/assets/icono3.png'), text: 'No es necesaria la asistencia física al oftalmólogo, por lo que todo es mucho más ágil.' }
        ]
      };
    }
  };
  </script>
  
  <style>
  .icon-row-container {
    display: flex;
  justify-content: space-around;
  padding: 0px 150px 0px 150px;
  padding-bottom: 100px;
    gap: 20px;
  }
  
  .icon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .icon-circle {
    width: 150px;
    height: 150px;
    border-radius: 50%; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; 
    margin-bottom: 10px;
  }
  
  .icon-circle img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .icon-text {
    text-align: center;
    max-width: 200px;
  }
  /* Existing styles... */

@media (max-width: 768px) {
  .icon-row-container {
    flex-direction: column;
    align-items: center;
  }

  .icon-item {
    width: 100%; /* Ensure items take full width on mobile */
  }

  .icon-circle {
    width: 100px; /* Adjust icon size for mobile */
    height: 100px;
    margin-bottom: 20px; /* Increase spacing for mobile */
  }

  .icon-text {
    text-align: center;
    max-width: 300px; /* Adjust text width for mobile */
    margin-bottom: 30px; /* Increase spacing between items */
  }
}

  </style>
  