<template>
  <div class="box">
    <a href="https://dicopt.com">
      <img alt="Dicopt logo" src="./../../assets/dicopt-logo-2.png">
    </a>
  </div>
</template>

<script>
  export default {
    name: 'LogoComponent',
  }
</script>

<style>
.box {
  padding: 5px 15px;
  margin-top: -15px;
}
</style>
  