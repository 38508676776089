<template>
  <div class="footer-container">
    <div class="footer-links">
      <a class="footer-box" href="https://dicopt.com/politica-de-cookies/">Política de cookies. </a>
      <a class="footer-box" href="https://dicopt.com/politica-de-proteccion-de-datos/" >Política de protección de datos. </a>
      <a class="footer-box" href="https://dicopt.com/condiciones-generales-de-la-subscripcion/">Condiciones generales de la suscripción. </a>
      <a class="footer-box" href="https://dicopt.com/seguridad/">Instrucciones de uso y seguridad. </a>
      <a class="footer-box" href="https://dicopt.com/contacto/">Contacto. </a>
      <a class="footer-box" href="https://dicopt.com/soporte/">Soporte. </a>
    </div>
    <div class="footer-info">
      <p class="footer-box">V-VISION® | La digitalización de la oftalmología y optometría</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FooterComponent',
  }
</script>

<style>
.footer-container {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  background-color: #0074B9 !important;
  color: white;
  padding: 30px;
}

.footer-box {
  margin-bottom: 10px;
}

.footer-links a {
  color: white; 
  text-decoration: none; 
}

.footer-info {
  margin-top: 20px;
}
</style>

