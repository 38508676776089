<template>
    <div class="box">
      <h1 class="title">
        <strong>
          Terapia para ojo vago con Realidad Virtual para usuarios de Avantsalud
        </strong>
      </h1>
    </div>
</template>
  
<script>
  export default {
    name: 'TitleComponent',
  }
</script>
  
<style>
    .title {
        font-weight: bold;
        text-overflow: ellipsis;
    }
    .box {
      padding-top: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
</style>
  