<template>
  <div class="main-component">
    <LogoComponent></LogoComponent>
    <TitleComponent></TitleComponent>
    <h3>Ha ocurrido en error... Vuelva a intentarlo</h3>
  
  </div>

</template>

<script>
import LogoComponent from '../Header/LogoComponent.vue';
import TitleComponent from '../Header/TitleComponent.vue';


export default {
  name: 'ErrorPageComponent',
  components: {
    LogoComponent,
    TitleComponent,
  },

};
</script>

<style>
/* Footer styles */
.footer {
  bottom: 0;
  min-width: 100%;
}

.main-component {
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
    flex-direction: column;
    min-height: 100vh;
}
</style>
