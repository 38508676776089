<template>
  <div class="flex-container">
    <div>
      <h3 class="button-title-text"><strong>Contrata Dicopt</strong></h3>
      <router-link class="link-container" to="/form"><strong>CONTRATA DICOPT</strong></router-link>
    </div>
    <div>
      <h3 class="button-title-text"><strong>Recíbelo en casa</strong></h3>
      <a class="link-container" href="https://dicopt.com"><strong>CONTACTANOS</strong></a>
    </div>
    <div>
      <h3 class="button-title-text"><strong>Empieza la terapia</strong></h3>
      <a class="link-container" href="https://www.canva.com/design/DAFtM7h_1ek/aMJJmcjyH0mPNDPjrytewA/view?utm_content=DAFtM7h_1ek&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink"><strong>GUÍA RÁPIDA DE DICOPT</strong></a>
    </div>
  </div>
</template>
  
<script>
  export default {
    name: 'ButtonsComponent',
  }
</script>

<style>
.button-title-text {
    border-bottom: 1px solid #5AA5DB;
    color: #3498db;
    margin-bottom: 50px;
    margin-top: -50px;
}
.flex-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

.flex-container > div {
  flex: 1;
  margin: 0 10px; 
}

.link-container {
  color: #454545;
    text-decoration: none;
    outline: none !important;
    -moz-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

@media (max-width: 767px) {
  .flex-container {
    flex-direction: column;
    align-items: stretch; 
    padding: 25px;
    margin-top:-50px;
  }

  .flex-container > div {
    margin: 10px 0; 
  }

  .button-title-text {
    border-bottom: 1px solid #5AA5DB;
    color: #3498db;
    margin-bottom: 25px;
    margin-top: 0px;
}
}
</style>
  