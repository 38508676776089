// router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import MainComponent from './components/MainComponent.vue'
import MainFormComponent from './components/Form/MainFormComponent.vue'
import ErrorPageComponent from './components/Error/ErrorPageComponent.vue'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
      {
        path: '/',
        name: 'main',
        component: MainComponent
      },
      {
        path: '/form',
        name: 'form',
        component: MainFormComponent
      },
      {
        path: '/error',
        name: 'error',
        component: ErrorPageComponent
      }
    ]
  })

  export default router;
